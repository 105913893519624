<!-- MAIN WRAPPER -->
<div class="flex flex-col">
    
    <!-- DVM AND PRICE SCALES -->
    <section class="lg:max-w-none lg:px-5 lg:grid lg:grid-cols-12 xl:mt-4 overflow-hidden">
       
        <!-- MAP & PRICE SCALES -->
        <div class="lg:col-span-8 xl:col-span-9 lg:bg-lightgray/30 dark:lg:bg-main/30 lg:p-5 lg:rounded-xl lg:mx-5 lg:flex lg:items-center lg:gap-x-3">

            <!-- Price Scales Desktop -->
            <div class="hidden w-[20rem] lg:block lg:h-full">
                <!-- Title -->
                <h3 class="font-bold text-darkgray dark:text-secondary text-2xl">
                    {{entity()!.venue | titlecase}}
                </h3>

                <p class="text-sm text-darkgray/70 dark:text-gray-400 ml-[1.5px]">
                    Select your price scale
                </p>

                <!-- Price Scales -->
                <!-- SELECT -->
                <div id="ScrollBox" class="lg:flex flex-col gap-y-4 overflow-y-auto max-h-[65vh] mt-5 p-[15px_4px_40px_4px] masked" *ngIf="priceScaleFilter() && !selectedPrice() else priceScaleSelected">
                   
                    <div *ngFor="let priceScale of priceScaleFilter() | keyvalue" 
                        (click)="selectPriceScale(priceScale.value.key)"
                        (mouseenter)="hoverPriceScale(priceScale.value)"
                        (mouseleave)="leavePriceScale()"
                        class="bg-white dark:bg-gray-800 rounded-lg py-2 px-3 w-full flex justify-between items-center cursor-pointer"
                        [ngClass]="{'ring-2 ring-secondary': hoveredPriceScale?.code === priceScale.value.code, 'hover:ring-2 hover:ring-secondary': !showDvm()}">
                       
                        <div>
                            <p class="text-sm font-semibold " 
                            [ngClass]="this.hoveredPriceScale?.code == priceScale.value.code  ? 'text-secondary' : 'text-darkgray'">
                                {{priceScale.value.name}}
                            </p>
                            <span class="text-xs text-darkgray/60 font-medium font-main"
                            [ngClass]="this.hoveredPriceScale?.code == priceScale.value.code  ? 'dark:text-secondary':''">
                                from {{priceScale.value.bestPrice| currency:'GBP'}}
                            </span>
                       </div>

                       <i [ngClass]="this.hoveredPriceScale?.code == priceScale.value.code  ? 'text-secondary' : 'text-darkgray/60'">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-current">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>                          
                       </i>

                    </div>

                </div>

                <!-- SELECTED -->
                <ng-template #priceScaleSelected>

                   <div class="lg:flex flex-col gap-y-4 overflow-y-auto min-h-[50vh] max-h-[55vh] mt-5 p-1 cursor-pointer">

                    <div class="bg-white dark:bg-gray-800 rounded-lg py-2 px-3 w-full flex justify-between items-center ring-2 ring-secondary" (click)="unselectPriceScale()">
                        
                        <div>
                            <p class="text-sm font-semibold text-secondary">
                                {{selectedPrice()?.name}}
                            </p>
                            <span class="text-xs text-darkgray/60 font-medium font-main dark:text-secondary">
                                from {{selectedPrice()!.bestPrice| currency:'GBP'}}
                            </span>
                        </div>

                        <i class="text-secondary">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-current">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>                          
                        </i>

                    </div>

                   </div>
                   
                </ng-template>

            </div>

            <!-- DVM -->
            <ng-container *ngIf="showDvm() else memberships">
                 <div class="h-[55dvh] lg:h-full w-full relative flex flex-col justify-center">
                    <dvm-map-viewer 
                        [service]="dvm.viewer"
                        #viewer
                    />
                </div>
            </ng-container>
           
            <!-- MEMBERSHIPS DESKTOP (PACK ITEMS)  -->
            <ng-template #memberships class="h-[55vh] grid lg:grid-cols-[auto_1fr]">
              
                <ng-container *ngIf="selectedPrice() && isMembership()">
                   
                    <!-- PACKS -->
                    <div class="hidden h-3/4 bg-white rounded-lg lg:flex flex-grow items-center justify-center">
                   
                        <div class="p-5 flex flex-col items-center justify-center h-2/3 text-center ">
                            <h5 class="text-secondary font-main font-semibold mb-3 text-2xl">
                                PACK CONTENTS
                            </h5>
                            <ul>
                                <li class="font-main font-medium text-darkgray text-lg" *ngFor="let item of membershipPackItems()">
                                    {{ item | titlecase }}
                                </li>
                            </ul>
                        </div>
                   
                    </div>

                    <!-- ARROW (Desktop) -->
                    <div class="hidden lg:flex w-5 items-center justify-center mx-4">
                   <i>
                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-14 h-14 text-secondary animate-pulse">
                           <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                       </svg>
                   </i>
                    </div>

                </ng-container>

            </ng-template>

            <!-- Price Scales Mobile -->
            <div class="bg-white dark:bg-gray-900 flex flex-col items-center p-4 lg:hidden">
                <div class="relative w-full h-20">
                    
                    <h3 class="font-bold text-darkgray dark:text-secondary text-center mb-2">
                        {{entity()!.venue | titlecase}}
                    </h3>
                    
                    <app-selector
                        class="justify-center"
                        [isMembership]="isMembership()"
                        [priceScales]="priceScaleFilter()" 
                        (selected)="selectPriceScale($event)"
                    />

                </div>
            </div>

        </div>

        <!-- BUYER TYPE SELECTOR | USER CART  -->
        <div class="lg:col-span-4 xl:col-span-3 container mx-auto">
            
            <!-- Event Info -->
            <div class="my-4 lg:my-5">
                <div class="px-5">
                        <h4 class="text-main dark:text-secondary font-main text-sm font-bold text-center mb-1 lg:text-left lg:text-base">
                            {{entity()!.name | uppercase}}
                        </h4>
                        <p class="text-darkgray text-xs text-center font-bold mb-2 lg:text-left lg:text-sm lg:font-medium">
                            {{entity()!.venue | uppercase}}
                            <span *ngIf="eventDate">
                                , {{ eventDate | date:'M/d/yy h:mm a'}}
                            </span>
                        </p>
                        <i class="lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mx-auto text-darkgray/60">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>  
                        </i>
                </div>
            </div>

            <!-- Associates and Cart -->
            <div id="ScrollBox" class="mb-5 px-3 flex flex-col gap-y-3 lg:h-[40vh] lg:overflow-y-auto lg:pb-3 lg:relative lg:mb-0 lg:pt-3" [ngClass]="{'masked':selectedPrice()}" *ngIf="associations.length">
               
                <app-price-selector 
                    *ngFor="let friend of associations"
                    (update)="updateCart($event)" 
                    [user]="friend" 
                    [disabled]="isTicketLimit"
                    [priceScale]="selectedPrice()!" 
                    [isMembership]="isMembership()" 
                />

            </div>

            <!-- 3D Visor --> 
            <ng-container *ngIf="showDvm() && has3d()">

                <div id="visor3d" class="mx-3 rounded-xl mb-20 lg:mb-0 transition-all duration-500 origin-center relative" [ngClass]="{'ease-out scale-0 hidden md:block': !selectedPrice(), ' ease-in scale-100': selectedPrice()}">
                    <dvm-viewer3d
                        [service]="dvm.viewer3d"
                        #viewer3d
                    />
                    <!-- CONTROLS -->
                    <ng-container *ngIf="dvm.views3d.length > 1">
                        <div class="absolute w-full flex justify-between top-1/2 px-2">
                            
                            <!-- PREVIOUS -->
                            <button class="bg-main rounded-full opacity-40 p-1 hover:opacity-100 transition-opacity rotate-180"  (click)="dvm.navigate3dView('prev')">
                                <i _ngcontent-ng-c1901152281="" class="text-white">
                                    <svg _ngcontent-ng-c1901152281="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-current">
                                        <path _ngcontent-ng-c1901152281="" stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
                                    </svg>
                                </i>
                            </button>
                            
                            <!-- NEXT -->
                            <button class="bg-main rounded-full opacity-40 p-1 hover:opacity-100 transition-opacity" (click)="dvm.navigate3dView()">
                                <i _ngcontent-ng-c1901152281="" class="text-white">
                                    <svg _ngcontent-ng-c1901152281="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-current">
                                        <path _ngcontent-ng-c1901152281="" stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
                                    </svg>
                                </i>
                            </button>
                            
                        </div>
                    </ng-container>
                </div>

            </ng-container>
            
            <!-- MEMBERSHIP PACK ITEMS (Mobile)   -->
            <ng-template *ngIf="selectedPrice() && !this.entity()?.inventoried">
                <div class="h-3/4 bg-white rounded-lg lg:flex flex-grow items-center justify-center mx-3 mb-10">
                
                    <div class="p-5 flex flex-col items-center justify-center h-2/3 text-center shadow">
                        <h5 class="text-secondary font-main font-semibold mb-3">
                            PACK CONTENTS
                        </h5>
                        <ul>
                            <li class="font-main font-medium text-darkgray" *ngFor="let item of membershipPackItems()">
                                {{ item | titlecase }}
                            </li>
                        </ul>
                    </div>
                
                </div>
            </ng-template>

        </div>
       
    </section>

    <!-- FOOTER -->
    <app-footer 
        [textDetail]="amountFormatted" 
        [showFooter]="cartQuantities.ticketQuantity > 0" 
        [disableButton]="cartQuantities.ticketQuantity === 0"
        (footerEvent)="launchModal()"
        buttonText="Continue to Checkout"
    />
    
</div>

<!-- LOADER -->
<ng-template #loader>
    <div class="w-screen h-[calc(100dvh-20vh)] flex overflow-hidden items-center justify-center">
        <app-loader class="block"/>
    </div>
</ng-template>
<div class="flex justify-between mx-5 lg:mx-10 items-center text-white">

    <button class="button w-fit py-2 px-3 text-xs sm:text-sm font-main" (click)="goBack()">
        Back to main view
    </button>

    <div class="bg-main p-2 sm:p-3 text-xs sm:text-sm rounded-lg w-fit">
        <span class="font-main font-medium">
            {{sectionId}}
        </span>
    </div>

</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSeatComponent } from './select-seat.component';
import { SelectPricesComponent } from './select-prices/select-prices.component';
import { SelectSeatsComponent } from './select-seats/select-seats.component';
import { SeatSelectorComponent } from 'src/app/shared/components/globals/seat-selector/seat-selector.component';
import { DvmTopbarComponent } from 'src/app/views/select-seat/select-seats/dvm-topbar/dvm-topbar.component';
import { PriceSelectorComponent } from 'src/app/shared/components/globals/price-selector/price-selector.component';
import { TruncatePipe } from 'src/app/utils/pipes/truncate.pipe';
import { FooterModule } from 'src/app/shared/components/globals/footer/footer.module';
import { DvmModule } from '@3ddv/ngx-dvm-internal';
import { LoaderModule } from 'src/app/shared/components/globals/loader/loader.module';
import { SelectorModule } from 'src/app/shared/components/globals/selector/selector.module';
import { BackButtonModule } from 'src/app/shared/components/globals/back-button/back-button.module';
import { FormsModule } from '@angular/forms';
import { LegendComponent } from './select-seats/legend/legend.component';

@NgModule({
  declarations: [
    SelectSeatComponent,
    SelectPricesComponent,
    SelectSeatsComponent,
    SeatSelectorComponent,
    DvmTopbarComponent,
    PriceSelectorComponent,
    TruncatePipe,
    LegendComponent,
  ],
  imports: [
    CommonModule,
    FooterModule,
    DvmModule,
    SelectorModule,
    LoaderModule,
    BackButtonModule,
    FormsModule
  ],
  exports: [
    SelectSeatComponent,
    SelectPricesComponent,
    SelectSeatsComponent
  ]
})
export class SelectSeatModule { }

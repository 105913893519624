<div class="flex flex-col items-center lg:flex-row lg:mx-10 lg:justify-between">
    <ul class="max-w-60 sm:max-w-72 lg:max-w-none lg:flex text-center lg:w-9/12 xl:w-8/12 md:justify-between cursor-default">
        <li class="my-6 lg:text-left lg:max-w-56 xl:max-w-72">
            <p class="text-secondary font-medium">Event</p>
            <p class="font-bold text-darkgray uppercase dark:text-gray-500">{{name}}</p>
        </li>
        <li class="my-6 lg:text-left lg:max-w-[14rem]">
            <p class="text-secondary font-medium">Venue</p>
            <p class="font-bold text-darkgray uppercase dark:text-gray-500">{{venue}}</p>
        </li>
        <li class="my-6 lg:text-left lg:max-w-[14rem]">
            <p class="text-secondary font-medium">Date & Time</p>
            <p class="font-bold text-darkgray uppercase dark:text-gray-500">{{date | date:'d/M/yy h:mm a'}}</p>
        </li>
    </ul>
    <div class="w-2/5 lg:w-1/6 xl:w-1/5 mb-10 mt-2 lg:my-0">
        <button *ngIf="showButton" class="button font-medium" [disabled]="!available" (click)="selectEvent()">
            {{available ? 'Select Event' : 'Unavailable'}}
        </button>
    </div>
</div>
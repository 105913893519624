import { Component, inject, computed, signal, Signal, WritableSignal, ViewChild, NgZone, Input, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DvmService } from 'src/app/core/services/dvm.service';
import { AssociationCollection, AssociationComplete } from 'src/app/shared/models/association.model';
import { PriceScale, PriceScaleFilter, PriceScaleCollection} from 'src/app/shared/models/availabilty/section.model';
import { Cart, CustomerCart, Item } from 'src/app/shared/models/cart.model';
import { Event } from 'src/app/shared/models/event.model';
import { availabilityHelpers } from 'src/app/utils/helpers/availability.helpers';
import { MapViewerComponent, MapViewerEvent, MapViewerService, Viewer3dComponent } from '@3ddv/ngx-dvm-internal';
import { MapViewerEventObject, MapViewerNode } from '@3ddv/dvm-internal'
import { SharedViewerEvent } from '@3ddv/ngx-dvm-internal/lib/map-viewer/shared/shared-viewer.service';
import { RunInZone } from 'src/app/utils/helpers/RunInZone';
import { ModalService } from 'src/app/core/services/modal.service';
import { cartHelpers } from 'src/app/utils/helpers/cart.helper';
import { CheckoutItem, FriendsFamily } from 'src/app/shared/models/checkout.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { SaleTransactionService } from 'src/app/core/services/override/checkout/sale-transaction.service';
import { MembershipTransactionService } from 'src/app/core/services/override/checkout/membership-transaction.service';
import { Package } from 'src/app/shared/models/package.model';
import { PackageTransactionService } from 'src/app/core/services/override/checkout/package-transaction.service';
import { familyEnclosureValidation } from 'src/app/utils/helpers/family-enclosure.helper';
import { ConfirmationModalParams, InformationModalParams } from 'src/app/shared/models/modal.model';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { APP_CONFIG } from 'src/configuration/configuration';
const { getBestPrice, findSectionsById, mergeAllSections } = availabilityHelpers();
const { filterCartQuantity } = cartHelpers()
const {initFamilyEnclosureValidator, hasSeatsOnFamilyArea, validateFamilyEnclosure} = familyEnclosureValidation()

@Component({
  selector: 'app-select-prices',
  templateUrl: './select-prices.component.html',
  styleUrls: ['./select-prices.component.css'],
  providers: [ CurrencyPipe ]
})
export class SelectPricesComponent implements OnInit, AfterViewInit, OnDestroy {
  
  //INPUTS
  @Input({required: true}) 
  entity!:       Signal<Event | Package>;

  @Input({required: true})
  associations!: AssociationComplete[];

  @Input({required: true})
  availability!: Signal<PriceScaleCollection>;

  @Input({required: true})
  type!: 'event' | 'package' | 'exchange';

  //VIEWCHILDS
  @ViewChild('viewer3d', {static: false})
  viewer3d!: Viewer3dComponent;

  // SERVICES

  public  dvm:                 DvmService                   = inject(DvmService);
  public  modalService:        ModalService                 = inject(ModalService);
  private currencyPipe:        CurrencyPipe                 = inject(CurrencyPipe);
  private ngZone:              NgZone                       = inject(NgZone);
  private router:              Router                       = inject(Router);
  private auth:                AuthService                  = inject(AuthService);
  private params:              Params                       = inject(ActivatedRoute).snapshot.queryParams;
  private saleCheckout:        SaleTransactionService       = inject(SaleTransactionService);
  private membershipCheckout:  MembershipTransactionService = inject(MembershipTransactionService);
  private packageCheckout:     PackageTransactionService    = inject(PackageTransactionService);
  private venues:              Configuration['venue']       = inject(APP_CONFIG).venue;
  
  // STATE

  protected CheckoutCart:      Cart                                   = {};                 // Objeto Carrito
  protected selectedPrice:     WritableSignal<PriceScale|undefined>   = signal(undefined);  // Price Scale Seleccionada
  protected hoveredPriceScale: PriceScale | null                      = null;               // Price Scale en la que se ha hecho hover desde el DVM
  protected readonly subs:     Subscription[]                         = [];                 // Subscripciones RXJS
  
  // COMPUTED

  protected isMembership:      Signal<boolean>   = computed(()=> this.entity()?.inventoried ? false : true);                         // Determina si es Membership o no
  protected bestPriceSelected: Signal<number>    = computed(()=> this.selectedPrice() ? getBestPrice(this.selectedPrice()!) : 0);    // Retorna el precio más bajo de la price scale seleccionada
  protected has3d:             Signal<boolean>   = computed(()=> this.venues[this.entity()?.venue!]?.has3d ?? false ? true : false); // Determina si el evento tiene 3D
  protected showDvm:           Signal<boolean>   = computed(()=> this.entity()?.inventoried && Object.keys(this.venues).includes(this.entity()?.venue) ? true : false); // Determina si se debe mostrar el DVM o no

  /**
   * Computed Signal que devuelve un objeto filtro con las PriceScales.
   * Para ello, recorre el objeto de disponibilidad y formatea los datos para que sean más legibles y accesibles.
   * Finalmente, devuelve un objeto con las PriceScales formateadas.
   */
  protected priceScaleFilter:  Signal<PriceScaleFilter>  = computed(()=> {
    const sections: PriceScaleFilter = {};
    const regex  = /\([^)]*\)/g; // Expresión regular para encontrar y eliminar texto entre paréntesis
    
    Object.entries(this.availability()).forEach(data => {
      const [key ,value]   = data;
      const formattedName  = value.name.replace(regex,'');
      const bestPrice      = getBestPrice(value);
      
      sections[key] = { 
        key:       key,
        name:      formattedName,
        bestPrice: bestPrice, 
        active:    false, 
        code:      value.code,
        prices:    value.prices,
        sections:  value.sections
      }
    })

    return sections;
  })
  
  /**
   * Retorna el array de items que se adjuntarán a la Membership Pack
   * @returns {string[]} 
   */
  protected membershipPackItems: Signal<string[]> = computed(()=> {
    
    if(!this.entity()?.inventoried && this.selectedPrice()){

      const RegExpGlobal:     RegExp = /\((.*?)\)/g;
      const RegExpIndividual: RegExp = /\((.*?)\)/;
      const text:             string = Object.values(this.selectedPrice()?.prices!)[0].name;
   
      const regexArray:       RegExpMatchArray = text.match(RegExpGlobal) as RegExpMatchArray;
      
      const items:            RegExpMatchArray = regexArray![regexArray?.length! - 1].match(RegExpIndividual) as RegExpMatchArray;
      
      let itemsArray = items![1].split(',').map(el => el.trim()) as string[];
      
      itemsArray = itemsArray.toString().split('&').toString().split(',');

      return itemsArray;

    }

    return [''];

  })

  // GETTERS

  /**
   * Retorna la ticketQuantity total y el balance total de todo el carrito actual.
   */
  protected get cartQuantities(): { ticketQuantity: number, totalAmount: number } {
   
    if(this.selectedPrice()){

    const cart:           CustomerCart[] = Object.values(this.CheckoutCart);
    let   ticketQuantity: number         = 0;
    let   totalAmount:    number         = 0;
    
    cart.forEach(CustomerCart =>{
      const data:       Item[]  = Object.values(CustomerCart);
      const quantity:   number  = data.reduce((acc,val)=> (acc += val.num_tickets!, acc), 0);
      const itemAmount: number  = data.reduce((acc,val)=> (acc += (val.price * val.num_tickets!), acc), 0)
      ticketQuantity += quantity;
      totalAmount += itemAmount;
    })
    
    return {ticketQuantity: ticketQuantity, totalAmount: totalAmount};
   } else {
    return {ticketQuantity: 0, totalAmount: 0};
   }

  }

  /**
   * Establece si se ha llegado al limite de tickets disponibles dentro de una pricescale
   */
  protected get isTicketLimit(): boolean {
    if(this.selectedPrice()){
      return this.cartQuantities.ticketQuantity === this.selectedPrice()!.seats_available ? true : false;
    }
    return false;
  }

  /**
   * Formatea el texto y el total del carrito para adecuarlo al diseño y mostrarlo en el Footer
   */
  protected get amountFormatted(): string {
    const text   = 'Subtotal: ';
    const amount = this.currencyPipe.transform(this.cartQuantities.totalAmount,'GBP');
    return  text + amount;
  }

  /**
   * Retorna el texto que se mostrará en el botón de confirmación
   */
  protected get eventDate(): Date | null {
    const event = this.entity() as Event;
    
    if(event.date){
      return event.date
    }else{
      return null
    }
  }

  
  public ngOnInit(): void {
    initFamilyEnclosureValidator(false);
  }

  public ngAfterViewInit(): void {
    this.initDvm();
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  /**
   * METODOS
   */
  protected initDvm(): void{
    
    const isIventoried: boolean = this.entity()?.inventoried,
          hasVenue:     boolean = Object.keys(this.venues).includes(this.entity()?.venue!);

    if(!isIventoried || !hasVenue){
     return;
    }

    const availability: string[] = mergeAllSections(this.availability());

    this.dvm.init(this.entity()?.venue!, availability);

    this.dvm.viewer.waitInitialize().subscribe(()=> {
      const click = this.dvm.viewer.getObservable('click').subscribe((event: MapViewerEvent<MapViewerEventObject>)=> this.onMapClick(event)),
            hover = this.dvm.viewer.getObservable('hover').subscribe((event: SharedViewerEvent<MapViewerEventObject, MapViewerService>)=> this.onMapHover(event)),
            leave = this.dvm.viewer.getObservable('leave').subscribe(()=> this.leavePriceScale());
      
      this.subs.push(click, hover, leave);

    })

  }

  /**
   * Metodo principal del componente mediante el cual se establece la signal **selectedPrice**. Esta signal sirve de input para
   * el componente hijo price selector, el cual establece el carrito y la quantity para la price scale seleccionada.
   * Adicionalmente, establece el filtro para saber cuando una price scale está activada o desactivada así como el reinicio del carrito en caso
   * de cambiar de price scale. 
   * Finalmente, si el DVM está encendido, carga el visor 3d y finalmente realiza un desplazamiento hasta la pricescale (seccion) seleccionada.
   * @param {keyof PriceScaleFilter} pricescale 
   */
  protected selectPriceScale(pricescale: keyof PriceScaleFilter): void {

    //Para version Mobile, cuando hay una elegida y no sea igual a la anterior 
    if(this.selectedPrice() && this.selectedPrice()?.code != this.priceScaleFilter()[pricescale].code){
      this.unselectPriceScale()
    }

    //Filtros para saber que price scale está active
    const activeFilter  = Object.values(this.priceScaleFilter()).filter(pricescale => pricescale.active === true)[0];
    const newFilter     = this.priceScaleFilter()[pricescale];
 
    if(activeFilter === undefined) {
      newFilter.active    = true;
    } else {
      activeFilter.active = false;
      newFilter.active    = true;
    }

    //Reiniciamos carrito al seleccionar price scale (cada vez que seleccionamos una nueva)
    this.CheckoutCart = {};
    
    //Establecemos la signal con la pricescale seleccionada
    this.ngZone.run(()=> this.selectedPrice.set(this.priceScaleFilter()[newFilter.key] as any)) 

    // VIEWER AND 3D
    if(this.showDvm()){

      this.dvm.viewer.goTo(this.selectedPrice()?.sections as any).subscribe(()=>{
        this.dvm.set3dViews(this.selectedPrice()?.sections!);
      });
  
      this.dvm.select(this.selectedPrice()?.sections!)
    }
  }

  /**
   * Al contrario que el método select, este método verifica si hay algun priceScale con la propiedad active en true y la revierte, y 
   * reestablece las signals y el carrito así como la posición del visor del mapa y del visor 3D.
   */
  protected unselectPriceScale(): void { 
      const activePriceScale = Object.values(this.priceScaleFilter()).filter(section => section.active === true)[0];
      
      activePriceScale ? activePriceScale.active = false : false;
      this.selectedPrice.set(undefined);

      if(this.showDvm()){
        this.dvm.resetSelectionAndPosition();
      }
  }

  /**
   * Función por la cual vinculamos el evento onClick del DVM al componente.
   * Si el nodo de DVM no tiene el estado **unavailable**, entra en un switch con dos posibilidades.
   * Si el nodo está SELECTED: Se llama a unselectPriceScale() y elimina la selección revirtiendo el estado del nodo. 
   * Si el nodo está AVAILABLE: Se consigue la priceScale a la que pertenece, si esta no está seleccionada, se llama al método selectPriceScale() para realizar la selección. 
   * @param {MapViewerEvent<MapViewerEventObject>} obj 
   */
  protected onMapClick(obj: MapViewerEvent<MapViewerEventObject>): void {
    const node = obj.event.nodes[0];
    if(node && node.state != 'unavailable'){
      switch(node.state){
        case 'selected':
          this.unselectPriceScale();
          break;
        case 'available':

          const priceScale = findSectionsById(Object.values(this.priceScaleFilter()) as any, node.id)[0];

          if(this.selectedPrice() && this.selectedPrice()?.code != priceScale.code){
            this.unselectPriceScale();
          }

          this.selectPriceScale(priceScale.key as string);
      }
    }
  }

  /**
   * Método que responde al Output del componente hijo Price Selector. Este componente hijo devuelve un objeto 
   * Carrito tal que {idCliente: Asientos}, con lo que guardamos la llave del idCliente para setear nuestro carrito interno
   * y le adjuntamos los valores. 
   * De este modo, si hay mas de un cliente, los datos de sus asientos estáran aislados el uno del otro. 
   * @param {Cart} cart 
   */
  protected updateCart(cart: Cart): void {
    const key: keyof Cart  = parseInt(Object.keys(cart)[0]);
    this.CheckoutCart[key] = Object.values(cart)[0];
  }
  
  /**
   * Evento lanzado por las cards de PriceScales de Desktop. Este método hace hover en las secciones que portan los price scales.
   * @param priceScale 
   */
  protected hoverPriceScale(priceScale: any): void {

    if(!this.showDvm()) return;

    this.hoveredPriceScale = priceScale;
    this.dvm.hover(priceScale.sections)

  }

  /**
   * Reinicia el estado del hover del DVM
   */
  protected leavePriceScale(): void {
    if(!this.showDvm()) return;

    this.hoveredPriceScale = null;
    this.dvm.hover();

  }

  /**
   * Recoge el evento emitido por DVM para identificar el id de la sección a que PriceScale pertence. De este modo podemos conseguir la bidireccionalidad
   * en el hover tanto en las cards como en el mismo DVM. 
   * Ejecutamos el decorador RunInZone para que el evento disparado por el DVM y por ende, de esta misma función, lance la detección de cambios de Angular. 
   * @param {SharedViewerEvent<MapViewerEventObject,MapViewerService>} event 
   */
  @RunInZone()
  protected onMapHover(event: SharedViewerEvent<MapViewerEventObject, MapViewerService>): void {
   const node: MapViewerNode = event.event.nodes[0];

   if(node.state === 'unavailable'){
     this.hoveredPriceScale = null
   }
   
   const PriceScales: PriceScale[] = Object.values(this.availability());
   this.hoveredPriceScale = findSectionsById(PriceScales,node.id)[0]
  }
  
  /**
   * Método por el cual se instancia el modal de confirmación. Este método, aplica las configuraciones de Confirmation y lanza el modal.
   * Si se acepta el botón del Modal, se lanza la función startCheckout.
   */
  protected launchModal(): void {

    const modalParams: ConfirmationModalParams = {
      title: `<span class="dark:text-secondary">Continue ?</span>`,
      content:  `
      <div class="text-left">
        <p class="text-base dark:text-gray-500">
          By clicking continue, your selection will be place on hold and you will be redirected to the checkout page.
        <p>
        <br>
        <p class="dark:text-secondary"><b>NOTE</b></p>
        <p class="text-red-500 text-sm mt-2 font-medium">
          JUVENILES UNDER THE AGE OF 16 WILL NOT BE PERMITTED ENTRY TO THE GROUND UNLESS ACCOMPANIED BY A PERSON OVER 18 YEARS
        </p>
        <p class="text-sm text-gray-500 mt-2 font-medium">
        AT STAMFORD BRIDGE, THE ENTIRE SHED END AND THE MATTHEW HARDING LOWER TIER ARE SAFE STANDING AREAS.
        </p>
      </div>
      `,
      onConfirm: ()=> this.isMembership() ? this.startMembershipCheckout() : this.startCheckout()
    }
    
    this.modalService.createConfirmationModal(modalParams);

  }
 
  /**
   * Instancia las principales variables con las que formular el objeto que finalmente se enviará a través de la petición.
   * Para ello se instancia el eventId y el priceScaleId. Además, se instancia un array donde irán informados mediante llave valor, el customer y sus asientos.
   * Para conseguir un resultado óptimo, filtramos el carrito actual, devolviendo el mismo formato pero eliminando los objetos con num tickets > 0, y si un customer no porta 
   * ningún objeto, lo elimina reduciendo el body a lo estrictamente necesario.
   * Finalmente con el body formateado, lanza una petición al endpoint, si es correcta avanza al nextStep, si no, lanza un modal de Error y retorna.
   */
  private startCheckout(): void {
    const entityId:             number  = this.entity()?.id!,
          priceScaleId:         string  = this.selectedPrice()?.key!,
          filteredCart:         Cart    = filterCartQuantity(this.CheckoutCart),
          friendsFamily:        FriendsFamily[] = [],
          hasSeatOnFamilyArea:  boolean = hasSeatsOnFamilyArea(filteredCart as any, false);

    let   isValidTransaction:   boolean = true;

    if(hasSeatOnFamilyArea){
      isValidTransaction = validateFamilyEnclosure(filteredCart, false);
    }

    if(!isValidTransaction){
      
      const modalParams: InformationModalParams = {
        title:   'System Message',
        content: 'The rules of Family enclosure are not met.'
      }

      this.modalService.createInformationModal(modalParams);

      return;
    }

    //Creamos un objeto con customer y seats por cada cliente en el carrito.
    for(const[key,value] of Object.entries(filteredCart)){
      let item: FriendsFamily = {
        customer: key,
        seats: value,
      }
      friendsFamily.push(item);
    }

    //Formalizamos el objeto final
    const finalData: CheckoutItem = {
      friends_family_accounts: friendsFamily,
      price_scale: priceScaleId
    }
    
    if(this.params['transaction']){
      finalData.from_transaction = this.params['transaction'];
    }

    switch(this.type){
      case 'event':
        finalData['event'] = entityId;
         this.saleCheckout.initCheckout(finalData).subscribe({
          next:  (v) => this.nextStep(v),
          error: (e) => e.error && e.error.message ? 
            this.modalService.createErrorModal({content: e.error.message}): 
            this.modalService.createErrorModal()
        })
      break;

      case 'package':
        finalData['package'] = entityId.toString();
        this.packageCheckout.initCheckout(finalData).subscribe({
          next:  (v) => this.nextStep(v),
          error: (e) => e.error && e.error.message ? 
          this.modalService.createErrorModal({content: e.error.message}): 
          this.modalService.createErrorModal()
        })
      break;
    }

  }

  /**
   * Método que se encarga de lanzar la petición de Membership Checkout.
   * Para ello, instancia las variables necesarias para formular el objeto que se enviará a través de la petición.
   * Además, se instancia un array donde irán informados mediante llave valor, el customer y sus asientos.
   * Para conseguir un resultado óptimo, filtramos el carrito actual, devolviendo el mismo formato pero eliminando los objetos con num tickets > 0, y si un customer no porta
   * ningún objeto, lo elimina reduciendo el body a lo estrictamente necesario.
   * Finalmente con el body formateado, lanza una petición al endpoint, si es correcta avanza al nextStep, si no, lanza un modal de Error y retorna.
  */
  private startMembershipCheckout(): void {
    
    const eventId:    number = this.entity()?.id!,
          priceScale: string = this.selectedPrice()?.key!,
          customerId: number = this.associations[0].associate_id,
          cart:       Cart   = filterCartQuantity(this.CheckoutCart);

    let buyerType: string = Object.keys(Object.values(cart)[0])[0];
    
    const data: any = {
      buyer_type: buyerType,
      event: eventId,
      patron_to_purchase: customerId,
      price_scale: priceScale
    }
    
    if(this.params['transaction']){
      data.from_transaction = this.params['transaction'];
    }

    this.membershipCheckout.initCheckout(data).subscribe({
      next:  (v) => this.nextStep(v),
      error: (e) => e.error && e.error.message ? 
      this.modalService.createErrorModal({content: e.error.message}): 
      this.modalService.createErrorModal()
    })
  }
  
  /**
   * Método que se encarga de redirigir al usuario a la página de Checkout.
   * @param {any} response 
   */
  private nextStep(response: any): void {

    const relatedTransactionId: string | undefined = this.params['transaction'];

    const params: any  = {transaction: relatedTransactionId ? relatedTransactionId : response.id} 

    switch(this.type){
      // EVENT CHECKOUT
      case 'event':
        params.type = this.entity()?.inventoried ? 'sale' : 'membership';
        this.auth.getUser(true).then(()=>{
          this.router.navigate(['buy-tickets/checkout'],{queryParams: params})
        });
      break;

      // PACKAGE CHECKOUT
      case 'package':
        params.type = 'package';
        this.auth.getUser(true).then(()=>{
          this.router.navigate(['buy-packages/checkout'],{queryParams: params})
        });
      break;
    }
   
  }
}

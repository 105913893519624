<div class="border-t dark:border-secondary bg-main/5 flex flex-col items-center relative">
    <!-- LABEL TEXT -->
    <div class="mt-4 lg:absolute lg:px-10 lg:mt-0 left-0 top-[clamp(15px,1vw,7px)]">
        <span class="block text-xs text-darkgray/60 font-main sm:text-sm lg:text-xs lg:opacity-85">
            Select supporters to purchase tickets for
        </span>
    </div>
    
    <!-- CURRENT USER -->
    <div class="text-center lg:flex lg:w-full lg:justify-between lg:items-center lg:p-10">

        <p class="font-bold text-darkgray text-base pt-4 text-center md:text-left lg:p-0 lg:text-lg">
            {{formatUsername(user) | uppercase}}
            
            <!-- LOYALTY POINTS -->
            <span class="block font-main font-bold text-darkgray/60 text-xs" *ngIf="user.tdc_info.loyalty_programs[0]">
                Loyalty Points Balance {{user.tdc_info.loyalty_programs[0].current_point_balance}}
            </span>
        </p>
        
        <!-- Respecto a los codigos en el selected es para que el tick aparezca en blanco en Safari Ios -->
        <!-- https://stackoverflow.com/questions/65343253/how-do-i-change-the-color-of-a-check-mark-to-white-in-ios-safari -->
        <button [ngClass]="{'button-secondary dark:bg-secondary' : isSelected(user.tdc_info.id)}" class="button dark:bg-main w-40 lg:w-56 font-medium my-4 lg:m-0" (click)="selectUser(user.tdc_info.id)">
            {{isSelected(user.tdc_info.id) ? 'Selected &#10004;&#xFE0E;' : 'Select'}}
        </button>

    </div>

    <!-- ASSOCIATIONS -->
    <div *ngFor="let friend of associations" class="w-full border-t dark:border-secondary text-center flex flex-col items-center py-4 lg:flex-row lg:justify-between lg:p-10 lg:text-left">
        <div>
            <span class="text-secondary font-main text-sm font-medium mb-1">
                Purchase tickets on behalf:
            </span>
            <!-- NAME -->
            <p class="font-bold text-darkgray text-base ">
                {{formatUsername(friend) | uppercase}}
            </p>
            <!-- SUPPORT NUMBER -->
            <p class="font-bold text-sm text-darkgray">
                Supporter number: {{friend.tdc_info.id}}
            </p>
            <!-- MEMBERSHIP -->
            <span class="block font-main font-bold text-darkgray/60 text-sm lg:text-base" *ngIf="friend.tdc_info.memberships[0]">
                {{friend.tdc_info.memberships[0].description}}
            </span>
            <!-- LOYALTY POINTS -->
            <span class="block font-main font-bold text-darkgray/60 text-xs" *ngIf="friend.tdc_info.loyalty_programs[0]">
                Loyalty Points Balance {{friend.tdc_info.loyalty_programs[0].current_point_balance}}
            </span>
        </div>
        <button [ngClass]="{'button-secondary dark:bg-secondary' : isSelected(friend.tdc_info.id)}" aria-label="Select Friend" role="button" class="button dark:bg-main w-40 lg:w-56 font-medium my-4" (click)="selectUser(friend.tdc_info.id)">
            {{isSelected(friend.tdc_info.id) ? 'Selected &#10004;&#xFE0E;' : 'Select'}}
        </button>
    </div>

</div>
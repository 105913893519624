import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'truncate'})
export class TruncatePipe implements PipeTransform {
    /**
     * Pipe que trunca el texto si este supera determinada longitud y lo sustituye por tres puntos supensivos (...)
     * @param { string } text - Texto a truncar
     * @param { number } limit - Cantidad de caracteres permitidos antes de truncar
     * @returns { string } texto truncado
     * @example 'Premium Field Club'| truncate:10 => 'Premium Fi...'
     * 
     */
    transform(text: string, limit: number): string {
        return text.length <= limit ? 
                text :
                text.slice(0,limit) + '...';
    }
}
<div class="relative">
    <!-- LEGEND -->
    <div class="p-2 w-fit bg-white/80 rounded-md cursor-default">
       
        <!-- TITLE -->
        <p class="text-darkgray font-medium mb-1">
            Legend
        </p>

        <!-- CODES -->
        <div class="flex flex-col">
            <p *ngFor="let code of holdCodesValues" class="font-medium text-darkgray text-sm flex items-center">
                <!-- BOLA DE COLOR -->
                <span class="w-3 h-3 rounded-full inline-block mr-1" [style]="{'background-color': code.color}"></span>
                <!-- NOMBRE DEL CODIGO -->
                {{capitalizeFirstLetter(code.name)}}
            </p>
        </div>

    </div>

    <!-- BUTTON -->
    <div class="absolute bottom-0 p-1 -left-[45px] rounded-full flex items-center group" [ngClass]="isActive ? 'bg-secondary text-white shadow-[0_0_7px_3px_theme(colors.lightgray)]' : 'bg-white'">
        <button [class]="isActive ? 'group-hover:text-white' : 'group-hover:text-secondary'" (click)="onButtonClick()">
            <i>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-current">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>  
            </i>
        </button>
    </div>
</div>
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { DestroyRef, EffectRef, Injectable, Signal, WritableSignal, computed, effect, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';
import { ModalService } from './modal.service';
import { ErrorModalParams } from 'src/app/shared/models/modal.model';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService<T,V> {

  constructor(
    private   destroy:  DestroyRef,
    protected http:     HttpClient,
    protected route:    ActivatedRoute,
    protected router:   Router,
    protected modal:    ModalService
  ) {}

  protected endpoint:       string = '/availability/';
  protected routeParams!:   string;
  protected returnRoute!:   string;

  private  entity$:         Observable<T[]>          = this.http.get<T[]>(this.rootUrl).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  public   entity:          Signal<T[]|any[]>        = toSignal(this.entity$, { initialValue: [] });

  private  availability$:   WritableSignal<V|any>    = signal(<V>{});
  public   availability:    Signal<V|any>            = computed(()=> this.availability$())

  private  entitySelected$: WritableSignal<T|any>    = signal(null);
  public   entitySelected:  Signal<T|any>            = computed(()=> this.entitySelected$());

  
  public setEntitySelected(args: T): void {
    this.entitySelected$.set(args);
  }

  public getSectionAvailability(entityId: string | number, additionalId: string | number): Observable<V> {
    return new Observable<V>();
  }

  public refresh(additionalId?: string): void {
    additionalId ? this.getAvailabiltyHandler(this.entity(), additionalId) : this.getAvailabiltyHandler(this.entity()!);
  }

  public resetAvailability(): void {
    this.resetAvailability$();
  }
    
  protected get rootUrl(): string {
    return ''
  }

  protected getAvailabilityParams(): HttpParams {    
    return new HttpParams({fromObject:{}})
  }

  protected launchErrorModal(message: string): void {
    
    const modalParams: ErrorModalParams = {
      content: message,
      onConfirm: () => this.router.navigate([this.returnRoute])
    }
    
    this.modal.createErrorModal(modalParams);
  }

  protected getAvailabiltyHandler(entity: T|any, additionalId?: string): void {
    let url = additionalId ? 
      this.rootUrl + entity + this.endpoint + additionalId :
      this.rootUrl + entity + this.endpoint;
      
      this.http.get<V>(url, {params: this.getAvailabilityParams()}).pipe(
      takeUntilDestroyed(this.destroy)).subscribe(availabilty => this.setAvailability(availabilty));
  }

  protected setAvailability(args: V): void {
    this.availability$.set(args)
  }

  private resetAvailability$(): void {
    this.availability$.set(<V>{});
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    // Si la petición falla mostramos un mensaje en consola. 
    console.error('An error occurred:', error.error);
  
    // Mostramos un modal con el mensaje de error y redirigimos al usuario a la ruta de retorno.
    this.launchErrorModal(error.error.message);

    return of(undefined)
  }

  protected selectedRecover: EffectRef = effect((): void =>{
    if((this.entity() && this.entity().length) && this.route.snapshot.queryParams[this.routeParams]){

      const key: keyof T[]  = this.route.snapshot.queryParams[this.routeParams];
      const selected: T|any = this.entity().filter(entity => entity.id == key)[0];

      selected ? this.setEntitySelected(selected) : this.router.navigate([this.returnRoute]);
    }
  },{allowSignalWrites:true})

  protected getAvailability: EffectRef = effect((): void =>{
    
    if(this.entitySelected()){

      this.resetAvailability$();

      this.getAvailabiltyHandler(this.entitySelected()!.id);

    }

  },{allowSignalWrites:true})
}

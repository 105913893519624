import { MapViewerInitOptions, MapViewerService, Viewer3dInitOptions, Viewer3dService} from '@3ddv/ngx-dvm-internal';
import { MapViewerLoadMapOptions, Viewer3dLoadView3dOptions, MapViewerPluginsList, MapViewerLoadObject, MapViewerStyles, MapViewerStylesState, MapViewerInputNodes, MapViewerInputNode, MapViewerLabelObject, MapViewerFlagsAPI } from '@3ddv/dvm-internal';
import { Injectable, inject } from '@angular/core';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { APP_CONFIG } from 'src/configuration/configuration';
import { Observable, tap } from 'rxjs';
import { HoldCodes } from 'src/app/shared/models/exchange/hold-code.modal';
import { ApiCoreFlags } from '@3ddv/ngx-dvm-internal/lib/map-viewer/apis/core';


@Injectable({
  providedIn: 'root'
})
export class DvmService {

  /**
   * APP CONFIGURATION
   */
  private AppConfiguration:     Configuration    = inject(APP_CONFIG); 	
  public readonly viewer:       MapViewerService = inject(MapViewerService);
  public readonly viewer3d:     Viewer3dService  = inject(Viewer3dService);

  /**
   * INIT CONFIG OPTIONS
   */
  public mapViewerInitOptions:  MapViewerInitOptions = {};
  public viewer3dInitOptions:   Viewer3dInitOptions  = {};
  
  /**
   * LOAD CONFIG OPTIONS
   */
  public mapViewerLoadOptions:  MapViewerLoadMapOptions   = { venue_id: '', map_id: '' };
  public viewer3dLoadOptions:   Viewer3dLoadView3dOptions = { venue_id: '', view_id:'' };

  /**
   * GENERAL CONFIG
   */
  public customStyles!:         HoldCodes | null;
  public customFlags!:          ApiCoreFlags | null;
  public views3d:               string[] = [];
  public view3dIndex:           number   = 0;
  

  public init(
    venueKey:     keyof Configuration['venue'], 
    availability: string[],
    plugin?:      MapViewerPluginsList,
    flags?:       ApiCoreFlags
    ): void {

      // Obtenemos los datos del Venue (si lo hay);
      const venueData: Configuration['venue'][''] | undefined = this.AppConfiguration.venue[venueKey];
      
      if(venueData){

        // Si vienen plugins informados, los pasamos al objeto de configuración inicial
        if(plugin){
          this.setPlugin(plugin);
        }
        
        // Si vienen flags las añadimos al objeto CustomFlags, si no, reiniciamos el objeto ( en caso de que se haya instanciado antes )
        flags ? this.customFlags = flags : this.customFlags = null;

        // Lanzamos el método de instanciación de los viewers
        this.setupViewers(venueData, availability);

      }else{
        this.closeViewers();
      }


  }

  public select(id: string | string[]): void{
    this.viewer.select(id);
  }

  public unselect(id: string | string[]): void {
    this.viewer.unselect(id);
  }

  public unselectAll(): void {
    this.viewer.unselectAll();
  }

  public hover(nodeId?: string | string[]): void {
    this.viewer.hover(nodeId ?? []);
  }

  public resetSelectionAndPosition(): void {
    this.unselectAll();
    this.viewer.goTo([0,0], 1.1);
  }
  
  public loadMap(loadOptions?: MapViewerLoadMapOptions): Observable<MapViewerLoadObject>{
    
    return this.viewer.loadMap(loadOptions ?? this.mapViewerLoadOptions).pipe(
      tap(()=> this.setFlags()),
      tap(()=> this.setStyles())
    );

  }

  public load3dView(id: string): void {
    this.viewer3dLoadOptions.view_id = id
    this.viewer3d.loadView3d(this.viewer3dLoadOptions).subscribe()
  }

  public set3dViews(views: string | string[]){
    this.views3d = views instanceof Array ? views : [views];
    this.view3dIndex = 0;
    this.load3dView(this.views3d[this.view3dIndex]);
  }
  
  public navigate3dView(direction: 'next'|'prev' =  'next'): void {

    if(this.views3d.length < 0 || this.views3d.length === 1){
      return;
    }

    if(direction === 'next'){
      this.view3dIndex = (this.view3dIndex + 1) % this.views3d.length;
    }else{
      this.view3dIndex = (this.view3dIndex - 1 + this.views3d.length) % this.views3d.length;
    }
  
    this.load3dView(this.views3d[this.view3dIndex]);
  
  }

  public setCustomStyles(styles: HoldCodes): void {
    this.customStyles = styles;
  }

  public setTags( elements: MapViewerInputNodes, tag: string ): void {
    this.viewer.setNodesTag(elements, tag);
  }

  public setLabel( node: MapViewerInputNode, label: MapViewerLabelObject ): void {
    this.viewer.updateLabel(node, label);
  }

  public setGroup(nodeId: string, group: string): void {
    this.viewer.addNodesToGroup([nodeId], group);
  }

  public removeGroup(nodeId: string, group: string): void {
    this.viewer.removeNodesFromGroup([nodeId], group);
  }

  public setAvailability( availability: string[] ): void {

    this.viewer.getTypesList().forEach((type) => this.viewer.setAvailability(type, availability));

  }

  private setStyles(): any {
    const styles = this.viewer.getStyles();
    
    this.setViewerStyles(styles, this.customStyles)
  }

  private setPlugin(plugin: MapViewerPluginsList): void {
    this.mapViewerInitOptions.plugins = [plugin];
  }

  private setFlags(): void {
    
    // DEFAULT FLAGS
    if(!this.customFlags){
      this.viewer.flags.max_zoom_on_first_limit = true;
      this.viewer.flags.fixed_aspect_ratio      = false;
      this.viewer.flags.automatic_selection     = false;
    }

    // CUSTOM FLAGS
    else {
      Object.entries(this.customFlags).forEach(([key, value]) => {
        if(this.viewer.flags.hasOwnProperty(key)){
            this.viewer.flags[key as keyof ApiCoreFlags] = value;
        }
      });
    }


  }

  private setupViewers(
    data: Configuration['venue'][''], 
    availability: string[], 
    ): void {
    
      //Si el Venue tiene Client lo añadimos, en caso contrario devolvemos el objeto vacio (por si anteriormente se habia instanciado con client)
      data.client ? this.mapViewerInitOptions.client_id = data.client : this.mapViewerInitOptions = {};
      
      //Agregamos las propiedades LoadOptions 
      this.mapViewerLoadOptions.map_id   = data.mapID;
      this.mapViewerLoadOptions.venue_id = data.venueID;
      this.viewer3dLoadOptions.venue_id  = data.venueID;

      // INIT MAP VIEWER
      this.viewer.initialize(this.mapViewerInitOptions).subscribe(()=> this.loadMap().subscribe(()=> this.setAvailability(availability)));
      
      // INIT 3D VIEWER
      if(data.has3d){
        this.viewer3d.initialize(this.viewer3dInitOptions).subscribe();
      }
      
  }
  
  private setViewerStyles( styles: MapViewerStyles, customStyles?: HoldCodes | null ): void {
    
    // THEME COLORS
    const dvmTheme = this.AppConfiguration.theme.dvm;

    // SECTION STYLES
    if(styles[0] && styles[0]['section']){

      const sectionAvailable: MapViewerStylesState = styles[0]['section']['available']! as MapViewerStylesState,
            sectionSelected:  MapViewerStylesState = styles[0]['section']['selected']! as MapViewerStylesState;

            // AVAILABLE NORMAL
            sectionAvailable.normal.none.fillStyle   = `rgb(${dvmTheme['main-color']})`;      
            sectionAvailable.normal.none.strokeStyle = `rgb(${dvmTheme['main-color']})`;

            // AVAILABLE HOVER
            sectionAvailable.hover!.none.fillStyle   = `rgb(${dvmTheme['secondary-color']})`;
            sectionAvailable.hover!.none.strokeStyle = `rgb(${dvmTheme['secondary-color']})`;

            // SELECTED NORMAL
            sectionSelected.normal.none.fillStyle   =  `rgb(${dvmTheme['selected-color']})`;
            sectionSelected.normal.none.strokeStyle =  `rgb(${dvmTheme['selected-color']})`;
            sectionSelected.normal.none.fillOpacity =   0.8;

            // SELECTED HOVER
            sectionSelected.hover!.none.fillStyle    =  `rgb(${dvmTheme['selected-color']})`;
            sectionSelected.hover!.none.strokeStyle  =  `rgb(${dvmTheme['selected-color']})`;
            sectionSelected.hover!.none.fillOpacity  =  0.5;

      styles[0]['section']['available'] = sectionAvailable;
      styles[0]['section']['selected']  = sectionSelected;

    }

    // SEAT STYLES
    if(styles[0] && styles[0]['seat']){
      
      const seatAvailable: MapViewerStylesState = styles[0]['seat']['available']! as MapViewerStylesState,
            seatSelected:  MapViewerStylesState = styles[0]['seat']['selected']! as MapViewerStylesState;
      
            // AVAILABLE HOVER
            seatAvailable.hover!.none.fillStyle   = `rgb(${dvmTheme['selected-color']})`;
            seatAvailable.hover!.none.strokeStyle = `rgb(${dvmTheme['selected-color']})`;

            // SELECTED NORMAL
            seatSelected.normal.none.fillStyle   =  `rgb(${dvmTheme['selected-color']})`;
            seatSelected.normal.none.strokeStyle =  `rgb(${dvmTheme['selected-color']})`;
            seatSelected.normal.none.fillOpacity =  0.8;

            // CUSTOM STYLES
            if(customStyles){

              for(const entry of Object.entries(customStyles)){
                const [key, code] = entry;                

                // AVAILABLE NORMAL
                seatAvailable.normal[key] = {
                  fillStyle:    code.color,
                  strokeStyle:  code.color,
                  lineWidth:    0.3,
                  text:         true,
                  textFontSize: 1,
                  cursor:       'pointer',
                }
                
                // AVAILABLE HOVER
                seatAvailable.hover![key] = {
                  fillStyle:    `rgb(${dvmTheme['selected-color']})`,
                  strokeStyle:  `rgb(${dvmTheme['selected-color']})`,
                  lineWidth:    0.3,
                  text:         true,
                  textFontSize: 1,
                  cursor:       'pointer'
                }

              }
              
            }

      styles[0]['seat']['available'] = seatAvailable;
      styles[0]['seat']['selected']  = seatSelected;

    }

    // SET STYLES
    this.viewer.setStyles(styles);

  }

  private closeViewers(): void {
    this.viewer.close();
    this.viewer3d.close();
  }

}

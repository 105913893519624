import { SeatCollection, SeatData } from 'src/app/shared/models/availabilty/seat.model';
import { Cart, CustomerCart, Item, SeatCart } from 'src/app/shared/models/cart.model';

export const cartHelpers = () => {
    
    /**
     * Método que recibe un objeto Carrito y lo formatea de modo que, mantiene su clave - valor pero filtra que los subObjetos no tengan num_ticket (quantity) > 0.
     * Si la quantity es > 0, se filtra y devuelve un objeto sin los subobjetos con dicha quantity. Si un customer no tiene subobjetos (porque todos están en quantity > 0) se 
     * elimina a ese customer del objeto final. 
     * @param {Cart} cart 
     * @returns cart
     */
    const filterCartQuantity = (cart: Cart): Cart => {
        return Object.fromEntries(
      Object.entries(cart)
        .map(([cliente, carrito]) => [
          cliente,
          Object.fromEntries(
            Object.entries(carrito as CustomerCart).filter(
              ([llave, item]) => item.num_tickets !== 0
            )
          ),
        ])
        .filter(([cliente, carrito]) => Object.keys(carrito).length > 0)
        );
     };
     
    const formatSeatCustomerCart = (customerCart: CustomerCart) : any => {
      let seatsArray: any = [],
          seatsObj:   any = {};

      for(let key in customerCart){

        const data: Item     = customerCart[key];
        let   id:   number   = data.id,
              seat: string   = key.split('-')[1];
           
        if(!seatsObj[id]){
          seatsObj[id] = {
            num_tickets: 0,
            code: data.name,
            name: data.name,
            seats_assigned: []
          }       
        }
        
        seatsObj[id].seats_assigned.push(parseInt(seat));
        seatsObj[id].num_tickets+=1;
      }

      return seatsObj;
    }

    /**
     * ### formatExchangeCart
     * 
     * @param cart 
     * @returns 
     */
    const formatExchangeCart = (cart: SeatCart): SeatCart[] => {
      
      const finalCart: SeatCart[] = [];

      for(const user in cart) {
        
        // Instanciamos el objeto que se va a devolver
        const formattedCart: {customer: string, seats: {[x:string]: SeatData}} = {
          customer: user,
          seats: {}
        };

        // Obtenemos el carrito del usuario
        const userCart: SeatCollection = cart[user];
        
        /**
         * Iteramos sobre el carrito del usuario y formateamos los datos:
         * 
         * 1. Eliminamos el atributo seats_assigned_og
         * 2. Reasignamos el atributo seats_assigned para que solo contenga el número de asiento
         */
        for(const seat in userCart) {
          
          // Obtenemos el asiento actual
          const actualSeat: SeatData = userCart[seat];
          
          // Reasignamos el atributo seats_assigned
          actualSeat.seats_assigned = actualSeat.seats_assigned!.map((seat: string) => seat.split('-')[1]);
          
          // Eliminamos el atributo seats_assigned_og
          delete actualSeat.seats_assigned_og;
          
          // Agregamos el asiento al objeto que se va a devolver
          formattedCart.seats[seat] = actualSeat;
  
        }

        // Si tiene asientos, lo agregamos al carrito final
        if(Object.keys(formattedCart.seats).length > 0){
          finalCart.push(formattedCart);
        }

      }

      return finalCart;
    }

    return {
        filterCartQuantity,
        formatSeatCustomerCart,
        formatExchangeCart
    }
};

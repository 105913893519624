import {Component, EventEmitter, Input, Output } from '@angular/core';
import { AssociationComplete } from 'src/app/shared/models/association.model';
import { User } from 'src/app/shared/models/user.model';

/**
 * ### Friends List 
 * Este componente forma parte del Card component. Se usa para mostrar un listado de asociados y seleccionarlos.
 */
@Component({
  selector: 'app-friends-list',
  templateUrl: './friends-list.component.html',
  styleUrls: ['./friends-list.component.css']
})
export class FriendsListComponent {

  @Input()  user!:          User;
  @Input()  associations!:  AssociationComplete[];
  @Input()  selectOne:      boolean  = false;
  @Output() onSelect:       EventEmitter<number[]> = new EventEmitter<number[]>();
  
  private   selectedUsers:  number[] = [];

  /**
   * Devuelve el nombre del usuario formateado uniendo Nombre y Apellido. 
   * @param {string} user 
   * @returns string - Nombre formateado
   */
  protected formatUsername(user: User | AssociationComplete): string {
    const name      = user.tdc_info.first_name;
    const lastname  = user.tdc_info.last_name;

    return name + ' ' + lastname;
  }

  /**
   * Si el contexto NO ES MEMBERSHIP, deja escoger tantos asociados como se quiera, si es Membership solo deja
   * escoger uno a la vez. Al escoger un usuario, se inserta en el arreglo de selectedUsers y se emite al componente padre.
   * @param {number} id 
   */
  protected selectUser(id: User['tdc_info']['id'] | AssociationComplete['tdc_info']['id']): void {
    this.selectOne ? 
      (()=> { 
        this.selectedUsers.includes(id) ? this.selectedUsers = [] : this.selectedUsers = [id];
      })() : 
        this.selectedUsers.includes(id) ? this.removeUser(id) : this.selectedUsers.push(id);
      
    this.onSelect.emit(this.selectedUsers);
  }

  /**
   * Elimina del array de seleccionados al asociado previamente seleccionado. 
   * @param {number} id 
   */
  protected removeUser(id: User['tdc_info']['id'] | AssociationComplete['tdc_info']['id']): void {
    
    const index = this.selectedUsers.indexOf(id);

    this.selectedUsers.splice(index, 1);

  }

  /**
   * Función helper que permite cambiar la apariencia de la template en los users que están o no seleccionados.
   * @param {number} id 
   * @returns boolean
   */
  protected isSelected(id: User['tdc_info']['id'] | AssociationComplete['tdc_info']['id']): boolean {
    return this.selectedUsers.includes(id) ? true : false;
  }

}

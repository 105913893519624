import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Package } from 'src/app/shared/models/package.model';

@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.css']
})
export class PackageDetailComponent {

  // Datos del Package
  @Input({required: true}) id!:         Package['id'];
  @Input({required: true}) name!:       Package['name'];
  @Input({required: true}) venue!:      Package['venue'];
  @Input({required: true}) available!:  Package['inventoried'];
  @Input({required: true}) showButton!: boolean;

  @Output() packageSelected: EventEmitter<void> = new EventEmitter<void>();

   /**
   * Emite a la card el valor del package seleccionado. 
   * @returns {Package}
   */
  protected selectPackage(): void {
    this.packageSelected.emit();
  }
}

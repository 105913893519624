import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Componentes
import { CardComponent } from './card.component';
import { EventDetailComponent } from './detail/event-detail/event-detail.component';
import { PackageDetailComponent } from './detail/package-detail/package-detail.component';

@NgModule({
  declarations: [
    CardComponent,
    EventDetailComponent,
    PackageDetailComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CardComponent,
  ]
})
export class CardModule { }

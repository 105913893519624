import { PriceScale, PriceScaleCollection } from "src/app/shared/models/availabilty/section.model";
import { Price } from "src/app/shared/models/availabilty/section.model";

export const availabilityHelpers = () => {

    /**
     * Esta función recibe como parametros la key de una seccion, y un objeto diccionario con llave y secciones.
     * Busca la sección en el objeto de filtros de sección y una vez encontrada, itera todos los valores de sus precios y
     * retorna el más bajo. 
     * @param { Section } section - Coleccion de secciones
     * @returns { number }
     */
    const getBestPrice = (pricescale: PriceScale): number => {
        let prices:       Price[]  = Object.values(pricescale.prices);
        let pricesArray:  number[] = [];
    
        prices.forEach(price => pricesArray.push(price.price));
        return Math.min(...pricesArray);
    }
    
    /**
     * Encuentra la Sección (Price Scale) dado el id de un nodo de sección DVM. 
     * @param { Array } array - Price Scale Array
     * @param  { string } id  - Node ID de la sección DVM. 
     * @returns Price Scale (Section)
     */
    const findSectionsById = (array: PriceScale[], id: string): PriceScale[] => {
        let pricescale: PriceScale[] = []; 
        for (let i = 0; i < array.length; i++) {
          const obj = array[i];
          const secciones = obj.sections;
          
          if(secciones.includes(id)){
           pricescale.push(obj);
          }
        }
        return pricescale as PriceScale[];
    }
    
    /**
     * Itera en la colección de Price Scales en su propiedad Sections. Retorna un array 
     * acumulando todas las secciones encontradas en dichas propiedades de cada Price Scale. 
     * @param sections - Price Scales
     * @returns string [] - Array de strings que son equivalentes a los nodos DVM
     */
    const mergeAllSections = (sections: PriceScaleCollection): string[] => {
        return Object.values(sections).reduce((acc,val)=>(val.sections.forEach(s => acc.push(s)),acc),[] as string[])
    }

    return { getBestPrice, findSectionsById, mergeAllSections };
}
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Event } from 'src/app/shared/models/event.model';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent {
  //Datos del Evento
  @Input({required:true}) id!:         Event['id'];
  @Input({required:true}) name!:       Event['name'];
  @Input({required:true}) venue!:      Event['venue'];
  @Input({required:true}) date!:       Event['date'];
  @Input({required:true}) available!:  Event['inventoried'];
  @Input({required:true}) showButton!: boolean;  
  
  //Emisor de eventos - se comunica con Card
  @Output() eventSelected: EventEmitter<void> = new EventEmitter<void>();
  
  /**
   * Emite a la card el valor del evento seleccionado. 
   * @returns {Event}
   */
  protected selectEvent(): void {
    this.eventSelected.emit()
  }
}

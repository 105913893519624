<div class="flex flex-col items-center lg:flex-row lg:mx-10 lg:justify-between">
    <ul class="max-w-[15rem] lg:max-w-none lg:flex text-center lg:w-8/12 md:gap-x-32 cursor-default">
        <li class="my-6 lg:text-left lg:max-w-[14rem]">
            <p class="text-secondary font-medium">Package</p>
            <p class="font-bold text-darkgray uppercase dark:text-gray-500">{{name}}</p>
        </li>
        <li class="my-6 lg:text-left lg:max-w-[14rem]">
            <p class="text-secondary font-medium">Venue</p>
            <p class="font-bold text-darkgray uppercase dark:text-gray-500">{{venue}}</p>
        </li>
    </ul>
    <button *ngIf="showButton" class="button w-2/5 lg:w-1/6 font-medium mb-10 mt-2 lg:my-0" [disabled]="!available" (click)="selectPackage()">
        {{available ? 'Select Event' : 'Unavailable'}}
    </button>
</div>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HoldCodes } from 'src/app/shared/models/exchange/hold-code.modal';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrl: './legend.component.css'
})
export class LegendComponent {
  
  @Input({ required: true })
  holdCodes!: HoldCodes;

  @Output()
  buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected isActive: boolean = false; // Gestiona estado interno del componente
  
  // GETTERS
  /**
   * Devuelve un array con los valores de los códigos de retención
   * @returns HoldCode[]
   */
  public get holdCodesValues(): HoldCodes[''][] {
    return Object.values(this.holdCodes);
  }

  // METHODS
  /**
   * Capitaliza la primera letra de un string
   * @param value string
   * @returns string
   **/
  public capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  /**
   * Emite un evento void al hacer click en un botón.
   * Se recoge en el componente padre y normalmente se usa 
   * para mostrar o escoder la leyenda.
   */
  protected onButtonClick(): void {
    this.isActive = !this.isActive;
    this.buttonClick.emit(this.isActive);
  }
}

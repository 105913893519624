import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Event } from 'src/app/shared/models/event.model';
import { Package } from 'src/app/shared/models/package.model';

type CardTypes = 'event' | 'package';

/**
 * ### Card Component
 * Este componente se utiliza en la selección de eventos, paquetes, amigos...
 * Se compone de distintos inputs que modifican su apariencia y funcionamiento. Sirve para renderizar datos, habitualmente como lista
 * y se compone de una parte superior; el detalle, y una de inferior, la descripción o contenido arbitrario. 
 * 
 * Por ejemplo:
 * En la selección de eventos, tenemos como parte superior el detail Event y como parte inferior la información del evento.
 * En la selección de amigos, tenemos una parte superior similar pero sin botón y la parte inferior es la lista de amigos. 
 * 
 * Para ello, el componente se divide en dos y cuenta con un ng-content para contenido arbitrario en la parte inferior de la Card.
 */
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  protected type!:      CardTypes;

  @Input()  event!:     Event;            // Event Data
  @Input()  package!:   Package;          // Package Data
  @Input()  showButton: boolean = true;   // Show or hide button (events)
  @Input()  showText:   boolean = true;   // Show or hide descriptions
  
  @Output() cardEvents: EventEmitter<void> = new EventEmitter<void>(); // Emisor de Eventos (Events | Packages | etc)
    
  ngOnInit(): void {
    this.initComponent()
  }

  /**
   * Determina la tipologia de la Card. (Events | Package | etc)
   */
  private initComponent(): void{
    this.event ? this.type = 'event' : this.type = 'package';
  }

  /**
   * Emisor de eventos para los card details.
   * Retorna el valor del objeto Event o Package de la Card seleccionada para consumir el dato
   * en el componente padre. 
   * @returns {void} 
   */
  protected selectItem(): void {
    this.cardEvents.emit();
  }
}

import { NgZone } from '@angular/core';

export interface ContextWithNgZoneInjected {
  ngZone: NgZone;
}

/**
 * A decorator to ensure a class method always runs inside Angular's zone.
 *
 * This is useful in any case when you want to ensure that change detection is triggered
 * after asynchronous operations or callback invocations that may occur outside
 * Angular's zone due to third-party library integrations or manual zone escaping.
 *
 * This is the case with DVM Angular which triggers `NgZone.runOutsideOfAngular` to prevent
 * `requestAnimationFrame` from bloating the call stack.
 *
 * The decorator checks if the current execution context is within Angular's zone
 * and explicitly runs the decorated method inside the zone if it's not. This
 * guarantees that Angular is aware of the changes and updates the view accordingly.
 *
 * @example
 * class SomeComponent {
 *   The NgZone is injected into the class and stored.
 *   constructor(ngZone: NgZone) {}
 *
 *  This method will now always execute within Angular's zone thanks to the decorator.
 *   @RunInZone()
 *   someNamedMethod() {
 *  Changes here will trigger Angular's change detection.
 *   }
 *
 *   @RunInZone()
 *   someArrowMethod = () => {
 *   }
 * }
 *
 * @returns {Function} A function that returns a method decorator.
 */
export function RunInZone() {
  return function (_: Object, __: string, descriptor?: PropertyDescriptor) {
    if (!descriptor) return;

    const originalFn = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const zone: NgZone = (this as ContextWithNgZoneInjected).ngZone;

      if (!zone) {
        return console.error('NgZone not found on the context instance');
      }

      if (NgZone.isInAngularZone()) {
        return originalFn.apply(this, args);
      } else {
        return zone.run(() => originalFn.apply(this, args));
      }
    };
  };
}
<div class="flex flex-col h-full">
    <!-- MAIN -->
    <div class="h-full lg:max-w-none lg:px-5 lg:grid lg:grid-cols-12 overflow-hidden">
        
        <!-- MAP -->
        <div class="lg:col-span-8 xl:col-span-9 lg:bg-lightgray/30 dark:lg:bg-main/30 lg:p-5 lg:rounded-xl lg:mx-5 lg:flex lg:items-center lg:gap-x-3">

            <!-- DVM -->
            <div class="h-[55dvh] lg:h-full w-full relative flex flex-col justify-center overflow-hidden">
                
                <!-- TOPBAR -->
                <div class="absolute top-2 left-0 z-10 w-full" *ngIf="showTopBar">
                    <app-dvm-topbar [sectionId]="sectionDescription" (back)="backToSectionView()"></app-dvm-topbar>
                </div>

                <!-- MAP -->
                <dvm-map-viewer [service]="dvm.viewer" #viewer></dvm-map-viewer>
                
                <!-- MINIMAP -->
                <ng-container *ngIf="showMinimap">
                    <div  class="absolute bottom-2 right-4 opacity-70 md:bottom-3 md:right-5 w-32 lg:w-52">
                        <dvm-navigable-minimap #minimap [linkedMapViewer]="viewer.service"></dvm-navigable-minimap>
                    </div>
                </ng-container>
            
                <!-- LEGEND -->
                <ng-container *ngIf="isExchange && holdCodes">
                    <div class="absolute bottom-[40%] transition ease-in -right-[190px] duration-200" [ngClass]="{'-translate-x-[105%]': activeLegend, 'opacity-0': !showLegend, 'opacity-100': showLegend}">
                        <app-legend [holdCodes]="holdCodes" (buttonClick)="toggleLegend( $event )"/>
                    </div>
                </ng-container>
                

            </div>

        </div>

        <!-- USER CART  -->
        <div class="lg:col-span-4 xl:col-span-3">

            <!-- EVENT INFO -->
            <div class="my-4 lg:my-5">
            
                <div class="px-5">
                       
                        <!-- Title -->
                        <h4 class="text-main dark:text-secondary font-main text-sm font-bold text-center mb-1 lg:text-left lg:text-base">
                            {{entity()!.name | uppercase}}
                        </h4>

                        <!-- Venue & Date -->
                        <p class="text-darkgray text-xs text-center font-bold mb-2 lg:text-left lg:text-sm lg:font-medium">
                            {{entity()!.venue | uppercase}} 
                            <span *ngIf="event()">
                                , {{event()!.date | date:'M/d/yy h:mm a'}}
                            </span>
                        </p>

                        <!-- CHEVRON DETAIL ( arrow down icon ) -->
                        <i class="lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mx-auto text-darkgray/60">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>  
                        </i>
                </div>

            </div>

            <!-- Associates and Cart -->
            <div id="ScrollBox" class="mb-10 px-3 flex flex-col gap-y-3 lg:h-[40vh] lg:overflow-y-auto lg:pb-3 lg:relative lg:mb-0 lg:pt-3" *ngIf="associations && userSelected">
                <app-seat-selector
                *ngFor="let friend of associations"
                    [user]="friend"
                    [userBuyerType]="isExchange ? userBuyerType(friend.tdc_info.birthday) : null"
                    [customerCart]="customerCart[friend.associate_id]"
                    [isSelected]="friend.associate_id === userSelected.associate_id"
                    [isExchange]="isExchange"
                    (selectUser)="changeSelectedUser($event)"
                    (openView)="openSeat3DView($event)"
                    (remove)="removeFromCart($event, true);"
                    (update)="updateCart(friend.associate_id, $event)"
                />
            </div>

            <!-- 3D Visor --> 
            <ng-container *ngIf="has3d()">
                <div id="visor3d"  class="mx-3 rounded-xl mb-20 lg:mb-0 transition-all duration-500 origin-center" [ngClass]="{'ease-out scale-0 hidden md:block': !showViewer3d  , ' ease-in scale-100': showViewer3d}">
                    <dvm-viewer3d
                        [service]="dvm.viewer3d"
                    #viewer3d/>
                </div> 
            </ng-container>
        </div>
       
    </div>

    <!-- FOOTER -->
    <app-footer
        [showFooter]="cartsAreEqual"
        [disableButton]="!cartsAreEqual"
        [textDetail]="totalPrice"
        (footerEvent)="launchModal()"
        buttonText="Continue to Checkout"
    />
</div>
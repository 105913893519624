import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dvm-topbar',
  templateUrl: './dvm-topbar.component.html',
  styleUrl: './dvm-topbar.component.css'
})
export class DvmTopbarComponent {

@Input({required: true})
sectionId!: string;

@Output()
back: EventEmitter<void> = new EventEmitter<void>()

// protected get formatedSection(): string {
//   let section = this.sectionId.split('_');
//   return `${section[1]}`
// }

protected goBack(): void {
  this.back.emit();
}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectFriendsComponent } from './select-friends.component';
import { FriendsListComponent } from 'src/app/shared/components/globals/friends-list/friends-list.component';
import { BackButtonModule } from 'src/app/shared/components/globals/back-button/back-button.module';
import { CardModule } from 'src/app/shared/components/globals/card/card.module';
import { FooterModule } from 'src/app/shared/components/globals/footer/footer.module';
import { LoaderModule } from 'src/app/shared/components/globals/loader/loader.module';

@NgModule({
  imports:[
    CommonModule,
    BackButtonModule,
    CardModule,
    FooterModule,
    LoaderModule
  ],
  declarations: [
    SelectFriendsComponent,
    FriendsListComponent,
  ],
  exports: [
    SelectFriendsComponent
  ]
})

export class SelectFriendsModule { }
